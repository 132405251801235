<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="152" height="70" viewBox="0 0 152 70" fill="none">
    <path
        d="M72.498 13.8905H42.8567C41.0155 13.8905 39.5229 15.3831 39.5229 17.2242V46.8656C39.5229 48.7067 41.0155 50.1993 42.8567 50.1993H72.498C74.3392 50.1993 75.8317 48.7067 75.8317 46.8656V17.2242C75.8317 15.3831 74.3392 13.8905 72.498 13.8905Z"
        fill="#FCC000"/>
    <path
        d="M57.6982 19.7019C59.3569 19.7019 60.4311 20.772 60.4311 22.2619C60.4311 23.7518 59.361 24.8218 57.6982 24.8218C56.0355 24.8218 55.0024 23.7518 55.0024 22.2619C55.0024 20.772 56.0725 19.7019 57.6982 19.7019ZM55.3482 44.3921V27.7234H60.0154V44.3921H55.3482Z"
        fill="white"/>
    <path
        d="M5.26806 14.4461H37.4735C38.1896 14.4461 38.1896 13.3349 37.4735 13.3349H5.26806C4.55192 13.3349 4.55192 14.4461 5.26806 14.4461Z"
        fill="black"/>
    <path
        d="M77.8813 14.4461H110.087C110.803 14.4461 110.803 13.3349 110.087 13.3349H77.8813C77.1652 13.3349 77.1652 14.4461 77.8813 14.4461Z"
        fill="black"/>
    <path
        d="M114.19 14.4461H146.396C147.112 14.4461 147.112 13.3349 146.396 13.3349H114.19C113.474 13.3349 113.474 14.4461 114.19 14.4461Z"
        fill="black"/>
    <path
        d="M5.26806 50.7549H37.4735C38.1896 50.7549 38.1896 49.6437 37.4735 49.6437H5.26806C4.55192 49.6437 4.55192 50.7549 5.26806 50.7549Z"
        fill="black"/>
    <path
        d="M112.696 48.1497V15.9443C112.696 15.2281 111.585 15.2281 111.585 15.9443V48.1497C111.585 48.8658 112.696 48.8658 112.696 48.1497Z"
        fill="black"/>
    <path
        d="M112.696 11.5857V3.51891C112.696 2.80278 111.585 2.80278 111.585 3.51891V11.5857C111.585 12.3018 112.696 12.3018 112.696 11.5857Z"
        fill="black"/>
    <path
        d="M40.0829 11.5857V3.51891C40.0829 2.80278 38.9717 2.80278 38.9717 3.51891V11.5857C38.9717 12.3018 40.0829 12.3018 40.0829 11.5857Z"
        fill="black"/>
    <path
        d="M76.3876 11.5857V3.51891C76.3876 2.80278 75.2764 2.80278 75.2764 3.51891V11.5857C75.2764 12.3018 76.3876 12.3018 76.3876 11.5857Z"
        fill="black"/>
    <path
        d="M112.696 66.4811V52.3189C112.696 51.6028 111.585 51.6028 111.585 52.3189V66.4811C111.585 67.1972 112.696 67.1972 112.696 66.4811Z"
        fill="black"/>
    <path
        d="M40.0829 66.4811V52.3189C40.0829 51.6028 38.9717 51.6028 38.9717 52.3189V66.4811C38.9717 67.1972 40.0829 67.1972 40.0829 66.4811Z"
        fill="black"/>
    <path
        d="M77.8813 50.7549H110.087C110.803 50.7549 110.803 49.6437 110.087 49.6437H77.8813C77.1652 49.6437 77.1652 50.7549 77.8813 50.7549Z"
        fill="black"/>
    <path
        d="M114.19 50.7549H146.396C147.112 50.7549 147.112 49.6437 146.396 49.6437H114.19C113.474 49.6437 113.474 50.7549 114.19 50.7549Z"
        fill="black"/>
    <path
        d="M26.3159 34.5349H16.4258V44.3879H11.6187V20.8749H16.4258V30.5221H26.3159V20.8749H31.123V44.3879H26.3159V34.5349Z"
        fill="black"/>
    <path
        d="M95.7519 19.8748V40.3463C95.7519 40.8649 95.7848 40.9678 96.3034 40.9678H98.1349V44.1492C97.4105 44.2891 96.3734 44.392 95.3691 44.392C92.1877 44.392 91.0806 42.8363 91.0806 39.5149V19.8748H95.7478H95.7519Z"
        fill="black"/>
    <path
        d="M132.057 19.8748V40.3463C132.057 40.8649 132.09 40.9678 132.608 40.9678H134.44V44.1492C133.715 44.2891 132.678 44.392 131.674 44.392C128.492 44.392 127.385 42.8363 127.385 39.5149V19.8748H132.052H132.057Z"
        fill="black"/>
    <path
        d="M48.2443 56.7062C49.3226 56.7062 50.4009 57.0149 50.9854 57.2372L50.4915 59.118C49.9811 58.9534 49.3226 58.7353 48.5365 58.7353C47.203 58.7353 46.0877 59.4638 46.0877 61.3817C46.0877 63.2996 47.166 64.0281 48.5365 64.0281C49.3226 64.0281 49.9811 63.81 50.4915 63.6453L50.9854 65.5262C50.4009 65.7443 49.3226 66.0571 48.2443 66.0571C45.2481 66.0571 43.5688 64.2298 43.5688 61.3817C43.5688 58.5336 45.2481 56.7062 48.2443 56.7062Z"
        fill="black"/>
    <path
        d="M54.5661 62.0937C54.6567 63.3366 55.4592 64.0857 56.7762 64.0857C57.7969 64.0857 58.7312 63.847 59.5873 63.5383L60.1552 65.308C59.2786 65.7278 57.9986 66.0571 56.5951 66.0571C53.5454 66.0571 52.0679 63.9581 52.0679 61.3652C52.0679 58.7723 53.603 56.7103 56.4676 56.7103C59.3321 56.7103 60.575 58.4472 60.575 60.5091C60.575 61.0771 60.501 61.7891 60.4475 62.0978H54.5661V62.0937ZM58.3114 60.5585C58.3279 60.468 58.3279 60.3774 58.3279 60.2828C58.3279 59.295 57.871 58.4924 56.484 58.4924C55.2411 58.4924 54.6196 59.4967 54.5661 60.5544H58.3114V60.5585Z"
        fill="black"/>
    <path
        d="M64.3738 56.982L64.7196 58.0397H64.7566C65.0859 57.4718 65.8143 56.7062 66.9832 56.7062C67.2919 56.7062 67.6417 56.7597 67.8598 56.8174L67.6417 58.8793C67.366 58.8258 66.9832 58.8053 66.7651 58.8053C65.7444 58.8053 65.1599 59.2786 64.8471 59.6654V65.7814H62.3818V56.9779H64.3738V56.982Z"
        fill="black"/>
    <path
        d="M76.589 63.8676C76.589 64.1433 76.6055 64.1968 76.8813 64.1968H77.5192V65.876C77.1364 65.9501 76.7701 66.0036 76.2392 66.0036C75.2885 66.0036 74.7946 65.6373 74.4859 64.9623H74.4489C73.8644 65.4562 72.6956 66.0571 71.6173 66.0571C69.6458 66.0571 68.6416 64.8883 68.6416 63.2831C68.6416 61.3282 69.8845 60.398 72.9878 60.398H74.1731V59.9947C74.1731 58.8999 73.2059 58.7353 72.675 58.7353C71.7078 58.7353 70.613 58.9905 69.7528 59.3732L69.1684 57.4923C70.0986 57.0725 71.4321 56.7062 72.8026 56.7062C75.342 56.7062 76.5808 58.0027 76.5808 60.2663V63.8635L76.589 63.8676ZM73.2471 61.9867C71.6214 61.9867 70.9999 62.4065 70.9999 63.065C70.9999 63.7935 71.4197 64.1227 72.1688 64.1227C72.8067 64.1227 73.6669 63.884 74.1772 63.5219V61.9867H73.2471Z"
        fill="black"/>
    <path
        d="M81.0836 56.982L81.3923 57.8421H81.4458C81.9561 57.3853 82.8698 56.7103 84.2774 56.7103C85.5738 56.7103 86.434 57.2042 86.9608 58.0603H86.9979C87.5453 57.6034 88.4054 56.7103 90.2122 56.7103C92.3483 56.7103 93.4431 58.1179 93.4431 60.2334V65.7855H90.9778V60.4886C90.9778 59.3362 90.6115 58.7353 89.4426 58.7353C88.566 58.7353 87.891 59.2291 87.4506 59.5749C87.5041 59.7765 87.5247 60.0152 87.5247 60.2334V65.7855H85.0594V60.4886C85.0594 59.3732 84.6766 58.7353 83.5077 58.7353C82.6311 58.7353 82.0096 59.1715 81.5898 59.4843V65.7855H79.1245V56.982H81.0795H81.0836Z"
        fill="black"/>
    <path
        d="M96.9001 52.7428C97.7768 52.7428 98.3406 53.3108 98.3406 54.0928C98.3406 54.8747 97.7726 55.4427 96.9001 55.4427C96.0276 55.4427 95.4761 54.8747 95.4761 54.0928C95.4761 53.3108 96.044 52.7428 96.9001 52.7428ZM95.6572 65.7814V56.9779H98.1225V65.7814H95.6572Z"
        fill="black"/>
    <path
        d="M104.646 56.7062C105.724 56.7062 106.803 57.0149 107.387 57.2372L106.893 59.118C106.383 58.9534 105.724 58.7353 104.938 58.7353C103.605 58.7353 102.49 59.4638 102.49 61.3817C102.49 63.2996 103.568 64.0281 104.938 64.0281C105.724 64.0281 106.383 63.81 106.893 63.6453L107.387 65.5262C106.803 65.7443 105.724 66.0571 104.646 66.0571C101.65 66.0571 99.9707 64.2298 99.9707 61.3817C99.9707 58.5336 101.65 56.7062 104.646 56.7062Z"
        fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'HillceramicIcon'
}
</script>
